<template>
  <section class="ig-news main-container">
    <div class="inner-container">
      <div class="title">
        <p class="gsap_news">{{ data.title_area.sub_title }}</p>
        <h2 class="gsap_news">{{ data.title_area.title }}</h2>
        <div class="gsap_news side-content" v-html="data.title_area.content_area"></div>
      </div>
      <div v-if="allPost.length > 0" class="ig-post">
        <article
          class="ig-post__item"
          v-for="(post, index) in allPost"
          :key="index"
        >
          <div class="ig-post__item__img">
            <img  :src="post.image ? post.image : defaultImage" />
          </div>
          <div class="ig-post__item__text">
            <div>{{ post.caption }}</div>
            <Button
              class
              text="See Post"
              :url="post.link"
              target="_blank"
              color="green"
            ></Button>
          </div>
        </article>
      </div>
    </div>
  </section>
</template>

<script>
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import Button from '@/components/utility/Button.vue'
import defaultImage from '@/assets/img/default-cover-news.jpg'

export default {
  data () {
    return {
      defaultImage
    }
  },
  components: {
    Button
  },
  props: ['data'],
  methods: {
    animations () {
      gsap.registerPlugin(ScrollTrigger)
      gsap.fromTo(
        '.ig-news .ig-post__item',
        { x: -100, autoAlpha: 0 },
        {
          scrollTrigger: {
            trigger: '.ig-news .ig-post',
            scroller: '[data-scroll-container]',
            start: this.$root.isMobile ? 'top center' : '-60% center',
            end: 'center center',
            markers: false
          },
          stagger: 0.3,
          x: 0,
          autoAlpha: 1
        }
      )
    }
  },
  computed: {
    allPost () {
      const latestThree = [
        this.$store.state.instagram[0],
        this.$store.state.instagram[1],
        this.$store.state.instagram[2]
      ]
      // console.log('lost post', latestThree)
      return latestThree
    }
  },
  created () {
  },
  mounted () {
    // console.log(this.data)
    this.animations()
  }
}
</script>
<style lang="scss" scoped>
.main-container {
  @apply bg-primary h-[100vh] h-full;
  .inner-container {
    @apply w-full max-w-screen-xl mx-auto py-16 px-4 gap-4 grid grid-cols-1 lg:grid-cols-4;
    .title {
      @apply w-full col-span-1;
      h2 {
        @apply text-3xl text-white;
      }
      div:deep() {
        @apply text-white mt-6 mb-0;
        pre {
          @apply text-accentGreen font-neueHaasUnica;
        }
      }
      p {
        @apply text-white;
      }
    }
    .ig-post {
      @apply col-span-3 w-full px-0 gap-4 md:px-3
      lg:p-0 flex flex-row justify-start justify-items-center
      lg:justify-center sm:justify-between overflow-x-auto lg:overflow-hidden snap snap-x snap-mandatory;
      &__item:first-child {
        @apply ml-0 lg:ml-3;
      }
      &__item {
        @apply flex-1 bg-white mb-4 snap-center w-full lg:w-4/12;
        &__img {
          @apply w-full h-80 overflow-hidden;
          img {
            @apply h-full w-full object-cover;
          }
        }
        &__text {
          @apply text-text p-4;
          span {
            @apply text-sm mr-2;
          }
          h3 {
            @apply text-xl mb-4 line-clamp-1 leading-8 max-h-[40px];
          }
          div:deep() {
            @apply line-clamp-3 min-h-[4.5rem] max-h-[4.5rem] overflow-hidden;
            * {
              @apply my-0 text-base font-normal leading-6;
            }
            *:not(:first-child) {
              @apply hidden;
            }
          }
        }
      }
    }
  }
}
</style>
